<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="5" :md="12" :sm="24" :xs="24">
            <a-form-item label="门店名称:">
              <a-input
                allow-clear
                v-model:value="where.shopName"
                placeholder="请输入门店名称"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="5" :md="12" :sm="24" :xs="24">
            <a-form-item label="联系人:">
              <a-input
                allow-clear
                v-model:value="where.linkman"
                placeholder="请输入联系人"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="5" :md="12" :sm="24" :xs="24">
            <a-form-item label="手机号:">
              <a-input
                allow-clear
                v-model:value="where.phone"
                placeholder="请输入手机号"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="5" :md="12" :sm="24" :xs="24">
            <a-form-item label="自提核销">
              <a-select
                v-model:value="where.isCheck"
                placeholder="是否支持自提核销"
                :options="checkList"
              ></a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="4" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="shopId"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
        @change="onTableChange"
      >
        <template #toolbar>
          <a-space>
            <a-button type="primary" @click="openEdit()"
              >新增 <template #icon> <PlusOutlined /> </template
            ></a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <template #logoPic="{ record }">
          <div class="image">
            <a-image
              :src="record.logoPic + '?width=120'"
              :width="120"
              :height="90"
            ></a-image>
          </div>
        </template>
        <template #isCheck="{ record }">
          <a-tag color="#2db7f5" v-if="record.isCheck === 1">支持</a-tag>
          <a-tag color="#f50" v-if="record.isCheck === 0">不支持</a-tag>
        </template>
        <template #status="{ record }">
          <a-tag color="#2db7f5" v-if="record.status === 1">启用</a-tag>
          <a-tag color="#f50" v-if="record.status === 0">禁用</a-tag>
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
            <a @click="openEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="确定要删除此分组吗？"
              @confirm="remove(record)"
            >
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 门店编辑弹窗 -->
    <shopEdit
      v-model:visible="showEdit"
      :data="current"
      @done="reload"
    ></shopEdit>

    <!-- 门店详情弹窗 -->
    <shopDetail v-model:visible="showDetail" :data="current"></shopDetail>
  </div>
</template>

<script>
import * as corpShopApi from '@/api/store/shop.js'
import shopEdit from './index-edit.vue'
import shopDetail from './index-detail.vue'
import { createVNode } from 'vue'
import {
  PlusOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue'
export default {
  name: 'order-index',
  components: {
    PlusOutlined,
    DeleteOutlined,
    shopDetail,
    // ExclamationCircleOutlined,
    shopEdit
  },
  data() {
    return {
      checkList: [
        {
          label: '支持',
          value: 1
        },
        {
          label: '不支持',
          value: 0
        }
      ],
      // 表格列配置
      columns: [
        {
          title: '门店ID',
          dataIndex: 'shopId',
          width: '100px',
          fixed: 'left'
        },
        {
          title: '门店logo',
          dataIndex: 'logoPic',
          sorter: true,
          slots: { customRender: 'logoPic' }
        },
        {
          title: '门店名称',
          dataIndex: 'shopName',
          sorter: true
        },
        {
          title: '门店简介',
          dataIndex: 'summary',
          sorter: true
        },
        {
          title: '营业时间',
          dataIndex: 'shopHours'
        },
        {
          title: '联系人',
          dataIndex: 'linkman',
          sorter: true
        },
        {
          title: '联系电话',
          dataIndex: 'phone',
          sorter: true
        },
        {
          title: '详细地址',
          dataIndex: 'address',
          sorter: true
        },
        {
          title: '自提核销',
          dataIndex: 'isCheck',
          slots: {
            customRender: 'isCheck'
          },
          sorter: true
        },
        {
          title: '状态',
          dataIndex: 'status',
          slots: {
            customRender: 'status'
          },
          sorter: true
        },
        {
          title: '排序',
          dataIndex: 'sort',
          sorter: true
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: '220px',
          align: 'center',
          slots: { customRender: 'action' },
          fixed: 'right'
        }
      ],
      // 表格搜索条件
      where: {
        corpId: this.getCorpId()
      },
      // 表格排序条件
      order: { sort: 'createTime', order: 'desc' },
      // 表格选中数据
      selection: [],
      // 是否显示详情弹窗
      showDetail: false,
      showEdit: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false
    }
  },
  methods: {
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      corpShopApi
        .deleteById(row.shopId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    // /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项目吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.shopId) }
          corpShopApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    datasource(option, callback) {
      corpShopApi
        .page({
          ...this.where,
          page: option.page,
          limit: option.limit,
          sort: 'createTime',
          order: 'desc'
        })
        .then((res) => {
          if (res.code === 0) {
            console.log('data', res.data)
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 表格分页、排序、筛选改变回调 */
    onTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.order.sort = sorter.field
        this.order.order = { ascend: 'asc', descend: 'desc' }[sorter.order]
      } else {
        this.order = {}
      }
      // this.reload()
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      const corpId = this.getCorpId()
      this.where = { corpId }
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 详情 */
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    /* 编辑 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    }
  }
}
</script>

<style scoped>
.image {
  width: 120px;
  height: 90px;
}

.image :deep(.ant-image-img) {
  height: 100%;
}
</style>
